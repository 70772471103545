<template>
  <div class="taked-books" v-click-outside="() => isModalOpen = false">
    <b-button
      v-if="!pageView "
      variant="warning"
      style="white-space: pre"
      @click="handleReturnBooks"
    >
      Return Books Back
    </b-button>
    <div
      v-if="isModalOpen || pageView"
      class="taked-books__modal taked-modal"
      :class="{ 'taked-modal_page': pageView }"
    >
      <div v-if="loading" class="taked-modal__spinner">
        <b-spinner small label="Spinning" />
      </div>
      <div v-if="!pageView" class="taked-modal__head">
        <div class="taked-modal__title">Taken Books – {{ books.length }}</div>
        <i
          class="mdi mdi-close"
          @click="isModalOpen = false"
        />
      </div>
      <div class="taked-modal__body">
        <transition-group name="list">
          <div
            v-for="book in books"
            :key="book.id"
            class="book"
          >
            <div
              class="book__cover"
              :style="{ backgroundColor: !book.photoUrl ? book.cover_color : '#53565B' }"
            >
              <img v-if="book.photoUrl" :src="book.photoUrl" alt="" />
              <template v-else>
                <div class="book__title book__title_light book__title_center">{{ book.title }}</div>
                <hr class="book__divider" />
                <div class="book__author">{{ book.author }}</div>
              </template>
            </div>
            <div class="book__info">
              <div class="book__title">{{ book.title }}</div>
              <div class="book__likes likes">
                <div :class="['likes__item', {'likes__item_green': isBookLiked(book) }]">
                  <div @click="handleLikeBook(book.id, true)">
                    <i class="bx bxs-like" />
                  </div>
                </div>
                <div :class="['likes__item', {'likes__item_red': isBookDisliked(book) }]">
                  <div @click="handleLikeBook(book.id, false)">
                    <i class="bx bxs-dislike" />
                  </div>
                </div>
              </div>
              <b-button
                variant="warning"
                @click="handleReturnBook(book.id)"
              >
                Return Back
              </b-button>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TakedBooks',
  props: {
    books: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageView: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isModalOpen: false
  }),
  methods: {
    isBookLiked(book) {
      return book.reviews.some((review) => review.is_like && review.employee_uid === this.user?.employee?.uid);
    },
    isBookDisliked(book) {
      return book.reviews.some((review) => !review.is_like && review.employee_uid === this.user?.employee?.uid);
    },
    handleLikeBook(bookId, isLike) {
      this.$emit('onLikeBook', { bookId, isLike });
    },
    handleReturnBook(bookId) {
      this.$emit('onReturnBook', bookId);
    },
    handleReturnBooks() {
      if (window.innerWidth < 768) {
        this.$router.push({ name: 'returnBooks' });
      } else {
        this.isModalOpen = true;
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  }
}
</script>

<style lang="scss" scoped>
.taked-books {
  position: relative;
  
  &__modal {
    right: 0px;
    z-index: 2;
    top: calc(100% + 20px);
    position: absolute;
  }
}
.taked-modal {
  width: 360px;
  padding: 20px 0px;
  color: #2A3042;
  border-radius: 20px;
  background: #F8F8FB;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

  &__spinner {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    
    .spinner-border {
      width: 40px;
      height: 40px;
      border-width: 4px;
      border-color: #556ee6;
      border-right-color: transparent;
    }
  }

  &__head {
    display: flex;
    padding: 0px 20px;
    align-items: center;
    padding-bottom: 7px;
    justify-content: space-between;

    i {
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__body {
    padding: 0px 20px;
    max-height: 440px;
    overflow: scroll;
  }

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  &_page {
    width: 100%;
    position: static;
    box-shadow: none;
    border-radius: 0px;
    background: unset;
    padding: 20px 0px 0px 0px;
  }

  &_page &__head {
    padding: 0px 0px 7px 0px;
  }

  &_page &__body {
   padding: 0;
   overflow: auto;
   max-height: unset;
  }
}
.book {
  display: grid;
  margin-top: 20px;
  background: #FFF;
  border-radius: 10px;
  grid-template-columns: 140px 1fr;

  &__cover {
    width: 140px;
    height: 200px;
    display: flex;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e2e2e5;

    img {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      margin: -10px;
      object-fit: cover;
    }
  }

  &__info {
    padding: 15px;
  }

  &__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;

    &_light {
      color: #fff;
    }

    &_center {
      text-align: center;
    }
  }

  &__divider {
    height: 1px;
    border: none;
    width: 100%;
    margin: 10px 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.20);
  }

  &__author {
    margin-top: 5px;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.60);
  }

  &__likes {
    margin: 10px 0px;
  }
}
.likes {
  gap: 10px;
  display: flex;
  align-items: center;

  &__item {
    & > div {
      width: 32px;
      height: 32px;
      display: flex;
      cursor: pointer;
      border-radius: 50%;
      background: #BBB;
      align-items: center;
      justify-content: center;

      i {
        color: #fff;
        font-size: 16px;
      }
    }
    &_green > div {
      background: #28A745;
    }
    &_red > div {
      background: #DC3545;
    }
    span {
      display: block;
      margin-top: 10px;
      color: #74788D;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      text-align: center;
    }
  }
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
