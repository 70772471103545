<template>
  <layout :loading="loading">
    <template #header>
      <h2 class="title-1">Library<span class="d-none d-md-inline"> - {{ totalRows }} books</span></h2>
    </template>
    <div class="library">
      <div class="library__header d-flex align-items-center justify-content-end">
        <TakedBooks
          v-if="takedBooks.length"
          class="mr-sm-2"
          :books="takedBooks"
          :loading="takedBooksLoading"
          @onLikeBook="handleLikeBook"
          @onReturnBook="handleReturnBook"
        />
        <template v-if="$hasAccess(['admin', 'office_manager'])">
          <multiselect
            :value="filters.employee"
            class="mr-2 library__employee-select d-none d-sm-block"
            :options="employeesOptions"
            track-by="uid"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            placeholder="Employees"
            @input="handleEmployeeChange"
          >
            <template #option="{ option }">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="20px"
                  class="mr-2"
                  variant="info"
                  :src="option.photo"
                />
                <span>{{ option.name }}</span>
              </div>
            </template>
          </multiselect>
          <b-dropdown
            right
            variant="primary"
            class="library__settings-button d-none d-sm-inline-flex"
          >
            <template #button-content>
              <i class="bx bx-dots-vertical-rounded" />
            </template>
            <b-dropdown-item @click="handleOpenBookModal">
              <div class="d-flex align-items-center">
                <i class="bx bx-plus font-size-16" />
                Add Book
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="downloadCSV">
              <div class="d-flex align-items-center">
                <i class="bx bxs-download font-size-16" />
                Download CSV
              </div>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="handleOpenSettings">
              <div class="d-flex align-items-center">
                <i class="bx bxs-cog font-size-16" />
                Settings
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </div>
      <book-slider v-if="newestBooks.length" :books="newestBooks" />
      <div v-if="isSettingsFetched" class="library__filters">
        <library-search
          :search="filters.search"
          @onChange="handleSearchChange"
        />
        <div>
          <library-category-filter
            :category="filters.category"
            @onChange="handleCategoriesChange"
          />
          <library-sorting-filter
            :sort="sort"
            :language="filters.languageId"
            @onLanguageChange="handleLanguageChange"
            @onSortChange="handleSortChange"
          />
        </div>
      </div>
      <div class="library__list">
        <Book
          v-for="book in books"
          :key="book.id"
          :id="book.id"
          :coverColor="book.cover_color"
          :coverImage="book.photoUrl"
          :title="book.title"
          :author="book.author"
          :tags="book.labels"
          :reviews="book.reviews"
          :category="book.category"
          @onCategoryClick="handleCategoriesChange"
        />
      </div>
      <div v-if="totalRows > 20" class="library__footer">
        <b-form-select
          :value="filters.pageLimit"
          class="library__per-page"
          @change="handlePageLimitChange"
        >
          <option
            v-for="limit in pageLimits"
            :key="limit"
            :value="limit"
          >
            {{ limit }} per page
          </option>
        </b-form-select>
        <b-pagination
          class="m-0"
          v-if="totalRows > filters.pageLimit"
          align="right"
          :value="filters.page"
          :per-page="filters.pageLimit"
          :total-rows="totalRows"
          @change="handlePaginationChange"
        />
      </div>
      <book-modal
        :open="isBookModalOpen"
        :mode="modalMode"
        :inventoryCode="inventoryCode"
        @onHide="handleCloseBookModal"
        @onSubmit="handleCreateBook"
      />
    </div>
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import BookModal from '@/components/book-modal.vue';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import AvatarDefault from '@/assets/images/users/avatar-default-rect.png'
import { FORM_MODE } from '@/utils/constants';
import { convertObjectToFormData } from '@/utils/converters';
import BookSlider from '@/components/library/book-slider.vue';
import Book from '@/components/library/book.vue';
import TakedBooks from '@/components/library/taked-books.vue';
import LibrarySearch from '@/components/library/library-search.vue';
import LibrarySortingFilter from '@/components/library/library-sorting-filter.vue';
import LibraryCategoryFilter from '@/components/library/library-category-filter.vue';
import { ALL_CATEGORIES_OPTION } from '@/utils/constants';

const ALL_EMPLOYEES_OBJ = { uid: null, name: 'All Employees' };

export default {
  name: 'Library',
  components: {
    Book,
    BookModal,
    BookSlider,
    TakedBooks,
    LibrarySearch,
    LibrarySortingFilter,
    LibraryCategoryFilter
  },
  data() {
    return {
      AvatarDefault,
      loading: false,
      inventoryCode: null,
      isBookModalOpen: false,
      takedBooksLoading: false,
      modalMode: FORM_MODE.CREATE,
      sort: 'newest',
      filters: {
        page: 1,
        search: '',
        pageLimit: 30,
        languageId: null,
        employee: ALL_EMPLOYEES_OBJ,
        category: ALL_CATEGORIES_OPTION,
      },
      isSettingsFetched: false,
      pageLimits: [20, 30, 40, 60, 80, 100]
    }
  },
  async mounted() {
    this.loading = true;
    await this.getLibrarySettings();
    this.isSettingsFetched = true;
    this.applyFiltersFromUrl(this.$route.query);
    await this.fetchBooks();
    await this.getNewestBooks();
    await this.getEmployeeTakedBooks();
    this.loading = false;
  },
  computed: {
    books() {
      return this.$store.state.library.list;
    },
    newestBooks() {
      return this.$store.state.library.newest;
    },
    takedBooks() {
      return this.$store.state.library.takedBooks;
    },
    totalRows() {
      return this.$store.state.library.totalItems;
    },
    user() {
      return this.$store.state.user.user;
    },
    employeesOptions() {
      return [
        ALL_EMPLOYEES_OBJ,
        ...this.$store.state.library.employees
      ];
    },
    languages() {
      return this.$store.state.library.languages;
    },
    categories() {
      return this.$store.state.library.categories;
    },
    queryParams() {
      const { page, pageLimit, search, employee, languageId, category } = this.filters;

      return {
        page,
        per_page: pageLimit,
        search: search || null,
        popular: this.sort === 'most_popular' ? 1 : null,
        employee_uid: employee.uid ? [employee.uid] : [],
        ['sort[created_at]']: this.sort === 'newest' ? 1 : null,
        library_item_category_id: category.id ? [category.id] : [],
        library_item_language_id: languageId ? [languageId] : [],
      }
    }
  },
  methods: {
    ...mapActions('library', [
      'likeBook',
      'getBooks',
      'createBook',
      'returnBook',
      'getNewestBooks',
      'uploadBookCover',
      'getLibrarySettings',
      'downloadLibraryCSV',
      'getEmployeeTakedBooks',
      'getCurrentInvetoryCode',
    ]),
    async fetchBooks() {
      await this.getBooks(this.queryParams);
    },
    async downloadCSV() {
      this.loading = true;
      await this.downloadLibraryCSV(this.queryParams);
      this.loading = false;
    },
    handleOpenSettings() {
      this.$router.push({ name: 'librarySettings' });
    },
    handleEmployeeChange(employee) {
      this.handleSetFilterValues({ page: 1, employee });
    },
    handleCategoriesChange(category) {
      if (this.filters.category.id !== category.id) {
        this.handleSetFilterValues({ page: 1, category });
      }
    },
    handlePageLimitChange(pageLimit) {
      this.handleSetFilterValues({ pageLimit, page: 1 });
    },
    handlePaginationChange(page) {
      this.handleSetFilterValues({ page });
    },
    async handleOpenBookModal() {
      this.loading = true;
      this.inventoryCode = await this.getCurrentInvetoryCode();
      this.isBookModalOpen = true;
      this.loading = false;
    },
    handleCloseBookModal() {
      this.isBookModalOpen = false;
    },
    async handleCreateBook({ data, file }) {
      this.loading = true;
      this.handleCloseBookModal();
      const response = await this.createBook(data);
      if (response?.id) {
        await this.uploadBookCover({
          id: response.id,
          data: file ? convertObjectToFormData({ file }) : { file }
        })
      }
      await this.fetchBooks();
      await this.getNewestBooks();
      this.loading = false;
    },
    async handleLikeBook({ bookId, isLike }) {
      this.takedBooksLoading = true;
      await this.likeBook({
        employee_uid: this.user.employee.uid,
        library_item_id: bookId,
        is_like: isLike
      });
      await this.getEmployeeTakedBooks();
      this.takedBooksLoading = false;
    },
    async handleReturnBook(bookId) {
      this.loading = true;
      this.takedBooksLoading = true;
      await this.returnBook(bookId);
      await this.fetchBooks();
      await this.getNewestBooks();
      await this.getEmployeeTakedBooks();
      this.takedBooksLoading = false;
      this.loading = false;
    },
    handleSearchChange: debounce(async function(value) {
      this.handleSetFilterValues({ search: value, page: 1 });
    }, 500),
    handleLanguageChange(languageId) {
      this.handleSetFilterValues({ languageId, page: 1 });
    },
    async handleSortChange(sort) {
      this.sort = sort;
      this.loading = true;
      await this.fetchBooks();
      this.loading = false;
    },
    handleSetFilterValues(values) {
      const filters = { ...this.filters, ...values };
      const query = {
        categoryId: filters.category.id,
        employeeId: filters.employee.uid,
        languageId: filters.languageId,
        page: filters.page,
        pageLimit: filters.pageLimit,
        search: filters.search
      }
      for (const key in query) {
        if (!query[key]) {
          delete query[key];
        }
      }
      this.$router.replace({ query });
    },
    applyFiltersFromUrl(urlFilters) {
      if (urlFilters) {
        this.filters.page = urlFilters?.page ?? 1;
        this.filters.search = urlFilters?.search ?? '';
        this.filters.pageLimit = this.pageLimits.includes(+urlFilters?.pageLimit) ? +urlFilters.pageLimit : 30;
        this.filters.languageId = this.languages.find(({ id }) => id === +urlFilters?.languageId)?.id ?? null;
        this.filters.employee = this.employeesOptions.find(({ uid }) => uid === urlFilters?.employeeId) ?? ALL_EMPLOYEES_OBJ;
        this.filters.category = this.categories.find(({ id }) => id === +urlFilters?.categoryId) ?? ALL_CATEGORIES_OPTION;
      }
    }
  },
  watch: {
    '$route.query': async function(value) {
      this.applyFiltersFromUrl(value);
      this.loading = true;
      await this.fetchBooks();
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.library {
  padding: 0 15px;
  &__header {
    margin-bottom: 25px;
  }
  &__settings-button {
    width: 30px;
    height: 36.53px;
    position: relative;

    .bx-dots-vertical-rounded {
      top: 50%;
      left: 50%;
      font-size: 20px;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  &__employee-select {
    width: 200px;
    min-height: 36.53px;

    :deep(.multiselect__single) {
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }

    :deep(.multiselect__tags) {
      padding-top: 7px !important;
      padding-right: 30px !important;
      min-height: 36.53px !important;
    }

    :deep(.multiselect__select) {
      top: 0px;
      width: 30px;
      padding: 0px;
      height: 100% !important;

      &:before {
        margin-top: 0px;
        right: unset;
        left: 50%;
        transform: translate(-50%, -50%)
      }
    }
  }
  .dropdown-menu {
    margin-top: 5px;
  }
  .dropdown-item {
    font-size: 13px;
    padding: 5px 20px;

    i {
      margin-right: 10px;
    }
  }
  .dropdown-divider {
    margin: 5px 20px;
  }
  &__new {
    margin-bottom: 61px;
    padding: 63px 70px 50px 70px;

    .book {
      padding: 0px 40px 0px 40px;
    }

    .card-body {
      padding: 0;
    }
  }
  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    justify-content: space-between;

    & > div {
      gap: 10px;
      display: flex;
      align-items: center;

      .dropdown {
        width: 250px;
      }
    }

  }
  &__list {
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(4, 1fr);
  }
  &__per-page {
    width: 140px;
  }
  &__footer {
    display: flex;
    margin-top: 35px;
    align-items: center;
    position: relative;
    height: 36px;
    .library__per-page {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &>ul {
      margin: 0 auto !important;
    }
  }
  @media(max-width: 1800px) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media(max-width: 1440px) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media(max-width: 1200px) {
    &__filters {
      .form-group {
        select {
          width: 150px;
        }
      }
    }
  }
  @media(max-width: 1040px) {
    &__list {
      grid-gap: 24px;
    }
  }
  @media(max-width: 992px) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media(max-width: 767px) {
    &__filters {
      flex-direction: column;
      align-items: flex-start;
      .library-search {
        margin-bottom: 10px;
      }

      div {
        width: 100% !important;
      }

      :deep(input) {
        width: 100% !important;
      }
    }
    &__list {
      gap: 24px;
      display: flex;
      flex-direction: column;
    }
  }
  @media(max-width: 520px) {
    &__per-page {
      display: none;
    }
    &__footer {
      margin-top: 40px;
      justify-content: center;
    }
  }
  @media (max-width: 400px) {
    padding: 0;
    margin: 0 -5px;
  }

  .book__img {
    box-shadow: none !important;
  }
}
</style>
